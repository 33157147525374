import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

import "bootstrap";
import "popper.js";
import "@nathanvda/cocoon";

import "./main.js";
import "./select2.full.min.js";
import flatpickr from 'flatpickr';
import { Japanese } from "flatpickr/dist/l10n/ja.js"

import * as ActiveStorage from "@rails/activestorage";

document.addEventListener('DOMContentLoaded', function () {
  // すべての日付入力フィールドにflatpickrを適用する関数
  function initializeFlatpickr(selector) {
    flatpickr(selector, {
      locale: Japanese, // 日本語のロケール
      dateFormat: "Y/m/d", // 日付フォーマットを 'YYYY/MM/DD' に指定
      onReady: function(selectedDates, dateStr, instance) {
        // Flatpickrが準備完了したら、遅延を加えてtodayクラスを削除
        setTimeout(function() {
          const todayElement = instance.calendarContainer.querySelector('.flatpickr-day.today');
          if (todayElement) {
            todayElement.classList.remove('today');
          }
        }, 0); // 遅延を加えてカレンダーの描画後に処理
      }
    });
  }

  // 初回ロード時に既存のすべての日付入力フィールドに適用
  initializeFlatpickr('.form-control[type="date"]');

  // Cocoon の after-insert イベントを使用して、新しい行が追加されたときに処理を実行
  document.addEventListener('cocoon:after-insert', function (e) {
    setTimeout(function() {
      const newElement = e.target.closest('.nested-fields'); // Cocoonで追加された新しい要素を取得
      if (newElement) {
        const dateFields = newElement.querySelectorAll('.form-control[type="date"]'); // 新しい行の日付フィールドを取得
        dateFields.forEach(function(field) {
          initializeFlatpickr(field); // 新しいフィールドにflatpickrを適用
        });
      }
    }, 200); // CocoonのDOM操作が完了するのを待ってから適用
  });

  // MutationObserverでDOMの変化を監視し、必要に応じてFlatpickrを適用する
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (node) {
        if (node.nodeType === 1) { // 要素ノードの場合
          const dateFields = node.querySelectorAll('.form-control[type="date"]');
          dateFields.forEach(function(field) {
            initializeFlatpickr(field); // 新しいフィールドにflatpickrを適用
          });
        }
      });
    });
  });

  // 監視対象の要素（例えばフォーム全体）を指定
  const targetNode = document.querySelector('body'); // 監視対象は必要に応じて適切に設定してください
  const config = { childList: true, subtree: true }; // 子ノードの追加を監視
  observer.observe(targetNode, config);
});
